import JazzLanding from './jazz-landing-prototype';

function App() {
  return (
    <div className="App">
      <JazzLanding />
    </div>
  );
}

export default App;