import React, { useState, useRef, useEffect } from 'react';
import { X, Play, Pause, SkipBack, SkipForward, Volume2, VolumeX } from 'lucide-react';
import { Album } from './types';

interface EnhancedAudioPlayerProps {
  album: Album;
  albums: Album[]; // Add all albums array
  isOpen: boolean;
  onClose: () => void;
  onAlbumChange?: (album: Album) => void; // Optional callback for album changes
}

const EnhancedAudioPlayer: React.FC<EnhancedAudioPlayerProps> = ({ 
  album, 
  albums, 
  isOpen, 
  onClose,
  onAlbumChange 
}) => {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [currentAlbumIndex, setCurrentAlbumIndex] = useState(
    albums.findIndex(a => a.id === album.id)
  );
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  const currentAlbum = albums[currentAlbumIndex];

  useEffect(() => {
    const handleEscapeKey = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);
    
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);
  
  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying, currentTrackIndex, currentAlbumIndex]);

  // Update current album index when album prop changes
  useEffect(() => {
    const newAlbumIndex = albums.findIndex(a => a.id === album.id);
    if (newAlbumIndex !== -1) {
      setCurrentAlbumIndex(newAlbumIndex);
      setCurrentTrackIndex(0);
    }
  }, [album, albums]);

  if (!isOpen) return null;

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
      setDuration(audioRef.current.duration);
    }
  };

  const handleSeek = (e: React.MouseEvent<HTMLDivElement>) => {
    if (audioRef.current) {
      const seekTime = (e.nativeEvent.offsetX / e.currentTarget.offsetWidth) * duration;
      audioRef.current.currentTime = seekTime;
      setCurrentTime(seekTime);
    }
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (audioRef.current) {
      const newVolume = Number(e.target.value);
      setVolume(newVolume);
      audioRef.current.volume = newVolume;
      setIsMuted(newVolume === 0);
    }
  };

  const toggleMute = () => {
    if (audioRef.current) {
      if (isMuted) {
        audioRef.current.volume = volume;
        setIsMuted(false);
      } else {
        audioRef.current.volume = 0;
        setIsMuted(true);
      }
    }
  };

  const switchToNextAlbum = () => {
    const nextAlbumIndex = (currentAlbumIndex + 1) % albums.length;
    setCurrentAlbumIndex(nextAlbumIndex);
    setCurrentTrackIndex(0);
    if (onAlbumChange) {
      onAlbumChange(albums[nextAlbumIndex]);
    }
  };

  const handleNext = () => {
    const isLastTrack = currentTrackIndex === currentAlbum.tracks.length - 1;
    
    if (isLastTrack) {
      switchToNextAlbum();
    } else {
      setCurrentTrackIndex(currentTrackIndex + 1);
    }
    setIsPlaying(true);
  };

  const handlePrevious = () => {
    if (currentTrackIndex === 0) {
      // If at first track, go to previous album's last track
      const prevAlbumIndex = currentAlbumIndex === 0 ? albums.length - 1 : currentAlbumIndex - 1;
      setCurrentAlbumIndex(prevAlbumIndex);
      setCurrentTrackIndex(albums[prevAlbumIndex].tracks.length - 1);
      if (onAlbumChange) {
        onAlbumChange(albums[prevAlbumIndex]);
      }
    } else {
      setCurrentTrackIndex(currentTrackIndex - 1);
    }
    setIsPlaying(true);
  };

  const handleTrackClick = (index: number) => {
    setCurrentTrackIndex(index);
    setIsPlaying(true);
  };

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div 
      className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50 p-4"
      onClick={handleOutsideClick}
    >
      <div className="w-full max-w-2xl bg-gray-900 text-white rounded-lg">
        <div className="flex justify-between items-center p-4 border-b border-gray-800">
          <h2 className="text-2xl font-bold">{currentAlbum.title}</h2>
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <X size={24} />
          </button>
        </div>
        
        <div className="p-6">
          <div className="mb-6">
            <div className="flex items-center gap-4 mb-4">
              <img 
                src={currentAlbum.image} 
                alt={currentAlbum.title} 
                className="w-24 h-24 rounded-lg object-cover"
              />
              <div>
                <h3 className="font-medium text-lg flex items-center gap-2">
                  <span className="text-gray-400 text-base">
                    {(currentTrackIndex + 1).toString().padStart(2, '0')}
                  </span>
                  <span className="text-white">
                    {currentAlbum.tracks[currentTrackIndex].title}
                  </span>
                </h3>
                <p className="text-gray-400 mt-1">
                  {currentAlbum.tracks[currentTrackIndex].duration}
                </p>
              </div>
            </div>

            <div className="bg-gray-800 p-4 rounded-lg">
              <div 
                className="h-1 bg-gray-700 rounded-full mb-4 cursor-pointer"
                onClick={handleSeek}
              >
                <div 
                  className="h-full bg-yellow-400 rounded-full relative"
                  style={{ width: `${(currentTime / duration) * 100 || 0}%` }}
                >
                  <div className="absolute right-0 top-1/2 -translate-y-1/2 w-3 h-3 bg-yellow-400 rounded-full" />
                </div>
              </div>

              <div className="flex justify-between text-sm text-gray-400 mb-4">
                <span>{formatTime(currentTime)}</span>
                <span>{formatTime(duration)}</span>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <button 
                    onClick={handlePrevious}
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    <SkipBack size={24} />
                  </button>
                  <button 
                    onClick={handlePlayPause}
                    className="bg-yellow-400 text-black p-2 rounded-full hover:bg-yellow-300 transition-colors"
                  >
                    {isPlaying ? <Pause size={24} /> : <Play size={24} />}
                  </button>
                  <button 
                    onClick={handleNext}
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    <SkipForward size={24} />
                  </button>
                </div>

                <div className="flex items-center gap-2">
                  <button 
                    onClick={toggleMute}
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    {isMuted ? <VolumeX size={24} /> : <Volume2 size={24} />}
                  </button>
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    value={isMuted ? 0 : volume}
                    onChange={handleVolumeChange}
                    className="w-24 accent-yellow-400"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-2 max-h-[300px] overflow-y-auto">
            {currentAlbum.tracks.map((track, index) => (
              <button
                key={track.id}
                onClick={() => handleTrackClick(index)}
                className={`w-full flex items-center justify-between p-3 rounded transition-colors
                  ${currentTrackIndex === index 
                    ? 'bg-yellow-400/20 text-yellow-400' 
                    : 'hover:bg-gray-800 text-white'
                  }`}
              >
                <div className="flex items-center gap-3 min-w-0">
                  <span className="text-sm text-gray-400 w-6">
                    {(index + 1).toString().padStart(2, '0')}
                  </span>
                  <span className="truncate">{track.title}</span>
                </div>
                <span className="text-sm text-gray-400 ml-2">
                  {track.duration}
                </span>
              </button>
            ))}
          </div>
        </div>

        <audio
          ref={audioRef}
          src={currentAlbum.tracks[currentTrackIndex].url}
          onTimeUpdate={handleTimeUpdate}
          onEnded={handleNext}
          onLoadedMetadata={handleTimeUpdate}
        />
      </div>
    </div>
  );
};

export default EnhancedAudioPlayer;