import React, { useState, useEffect } from 'react';
import { MenuIcon, X, Play, ChevronDown } from 'lucide-react';
import EnhancedAudioPlayer from './EnhancedAudioPlayer';
import { Youtube, MessageCircle } from 'lucide-react';

// Типы
export interface NavItem {
  id: string;
  text: string;
}

export interface Track {
  id: number;
  title: string;
  duration: string;
  url: string;
}

export interface Album {
  id: number;
  title: string;
  image: string;
  tracks: Track[];
}

interface StarPosition {
  left: number;
  top: number;
}

interface Star extends StarPosition {
  size: number;
  duration: number;
  delay: number;
}

const getAllImagePaths = () => {
  try {
    const context = require.context(
      '../public/images/galery',
      true, 
      /\.(jpg|jpeg|png|gif)$/i
    );
    return context.keys().map((path) => {
      return '/images/galery' + path.substring(1);
    });
  } catch (error) {
    console.error('Ошибка при загрузке изображений:', error);
    return [];
  }
};

// Компонент для отображения изображений в галерее
const GalleryImage: React.FC<{ src: string; index: number }> = ({ src, index }) => {
  const [isLoaded, setIsLoaded] = useState(true);
  const [hasError, setHasError] = useState(false);

  if (hasError || !isLoaded) {
    return null;
  }

  return (
    <div className="relative mb-4 break-inside-avoid">
      <img
        src={src}
        alt={`Gallery ${index + 1}`}
        className="w-full rounded-lg hover:scale-105 transition-transform duration-300"
        loading="lazy"
        onError={() => {
          setHasError(true);
          setIsLoaded(false);
        }}
        onLoad={() => setIsLoaded(true)}
      />
    </div>
  );
};

// Компонент Stars
const Stars: React.FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  const getRandomPosition = (): StarPosition => {
    if (isMobile) {
      const edge = Math.floor(Math.random() * 4);
      let left: number, top: number;

      switch (edge) {
        case 0: // top
          left = Math.random() * 100;
          top = Math.random() * 20;
          break;
        case 1: // right
          left = 80 + Math.random() * 20;
          top = Math.random() * 100;
          break;
        case 2: // bottom
          left = Math.random() * 100;
          top = 80 + Math.random() * 20;
          break;
        case 3: // left
          left = Math.random() * 20;
          top = Math.random() * 100;
          break;
        default:
          left = 0;
          top = 0;
      }
      return { left, top };
    } else {
      const safeZoneTop = 20;
      const safeZoneBottom = 80;
      const safeZoneLeft = 30;
      const safeZoneRight = 70;

      let left: number, top: number;

      do {
        left = Math.random() * 100;
        top = Math.random() * 100;
      } while (
        left > safeZoneLeft && 
        left < safeZoneRight && 
        top > safeZoneTop && 
        top < safeZoneBottom
      );

      return { left, top };
    }
  };

  const [positions, setPositions] = useState<StarPosition[]>(
    Array(30).fill(null).map(() => getRandomPosition())
  );

  useEffect(() => {
    const handleResize = (): void => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleAnimationEnd = (index: number): void => {
    setPositions(prevPositions => {
      const newPositions = [...prevPositions];
      newPositions[index] = getRandomPosition();
      return newPositions;
    });
  };

  const stars: Star[] = positions.map(position => ({
    ...position,
    size: Math.random() * 30 + 20,
    duration: Math.random() * 3 + 2,
    delay: Math.random() * 4
  }));

  const starSvg = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <g fill="white">
        <circle cx="50" cy="50" r="2" />
        <rect x="48" y="10" width="4" height="80" transform="rotate(0, 50, 50)" />
        <rect x="48" y="10" width="4" height="80" transform="rotate(45, 50, 50)" />
        <rect x="48" y="10" width="4" height="80" transform="rotate(90, 50, 50)" />
        <rect x="48" y="10" width="4" height="80" transform="rotate(135, 50, 50)" />
        <rect x="48.5" y="20" width="3" height="60" transform="rotate(22.5, 50, 50)" />
        <rect x="48.5" y="20" width="3" height="60" transform="rotate(67.5, 50, 50)" />
        <rect x="48.5" y="20" width="3" height="60" transform="rotate(112.5, 50, 50)" />
        <rect x="48.5" y="20" width="3" height="60" transform="rotate(157.5, 50, 50)" />
      </g>
    </svg>
  `;

  return (
    <div className="stars-container">
      {stars.map((star, i) => (
        <div
          key={i}
          className="star"
          style={{
            width: `${star.size}px`,
            height: `${star.size}px`,
            left: `${star.left}%`,
            top: `${star.top}%`,
            animationDuration: `${star.duration}s`,
            animationDelay: `${star.delay}s`
          }}
          onAnimationEnd={() => handleAnimationEnd(i)}
          dangerouslySetInnerHTML={{ __html: starSvg }}
        />
      ))}
    </div>
  );
};

// Начало компонента LandingPage
const LandingPage: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [selectedAlbum, setSelectedAlbum] = useState<Album | null>(null);
  const [images, setImages] = useState<string[]>([]); // Инициализация пустым массивом

  // Добавляем useEffect для загрузки изображений
  useEffect(() => {
    const loadImages = () => {
      const imagePaths = getAllImagePaths();
      setImages(imagePaths);
    };
    
    loadImages();
  }, []); // Пустой массив зависимостей означает, что эффект выполнится только при монтировании


  const menuItems: NavItem[] = [
    { id: 'home', text: 'Главная' },
    { id: 'albums', text: 'Альбомы' },
    { id: 'galery', text: 'Галерея' },
    { id: 'events', text: 'Афиша' },
    { id: 'contacts', text: 'Контакты' }
  ];

  const scrollToSection = (id: string): void => {
    const element = document.getElementById(id);
    if (element) {
      const offset = 80;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  const handleNavClick = (e: React.MouseEvent<HTMLAnchorElement>, id: string): void => {
    e.preventDefault();
    scrollToSection(id);
    setIsMenuOpen(false);
  };

  const albums: Album[] = [
    {
      id: 1,
      title: "Sixteen Tons",
      image: "/images/albums/sixten-tons.jpg",
      tracks: [
        { id: 1, title: "If I Were Rotshild - Dr.V.Aks-Band", duration: "3:23", url: "/audio/sixteen_tons/if_i_were_rotshild_drvaks_band.mp3" },
        { id: 2, title: "You're Driving Me Crazy - Garry Croony", duration: "3:55", url: "/audio/sixteen_tons/youre_driving_me_crazy_garry_croony.mp3" },
        { id: 3, title: "When I'm 64 - TeaForTwo & Dr.V.Aks-Band", duration: "4:08", url: "/audio/sixteen_tons/when_im_64_teafortwodrvaks_band.mp3" },
        { id: 4, title: "The Way You Look Tonight - Garry Croony & Dr.V.Aks Band", duration: "4:01", url: "/audio/sixteen_tons/the_way_you_look_tonight_garry_croonydrvaks_band.mp3" },
        { id: 5, title: "Always - TeaForTwo", duration: "2:38", url: "/audio/sixteen_tons/always_teafortwo.mp3" },
        { id: 6, title: "We Three - Garry Croony & Dr.V.Aks-Band", duration: "3:58", url: "/audio/sixteen_tons/we_three_garry_croonydrvaks_band.mp3" },
        { id: 7, title: "I'm Gonna Sit Right Down - Garry Croony & Dr.V.Aks Band", duration: "3:01", url: "/audio/sixteen_tons/im_gonna_sit_right_down_garry_croonydr_v_aks_band.mp3" },
        { id: 8, title: "Then I'll Be Tired Of You - TeaForTwo", duration: "3:20", url: "/audio/sixteen_tons/then_ill_be_tired_of_you_teafortwo.mp3" },
        { id: 9, title: "Drive My Car - TeaForTwo & Dr.V.Aks-Band", duration: "2:51", url: "/audio/sixteen_tons/drive_my_car_teafortwodrvaks_band.mp3" },
        { id: 10, title: "Just One Of Those Things - Garry Croony & Dr.V.Aks-Band", duration: "3:35", url: "/audio/sixteen_tons/just_one_of_those_things_garry_croonydrvaks_band.mp3" },
        { id: 11, title: "Sixteen Tons - Garry Croony & Dr.V.Aks-Band", duration: "3:49", url: "/audio/sixteen_tons/sixteen_tons_garry_croonydrvaks_band.mp3" },
        { id: 12, title: "Waltz For Debbie - TeaForTwo", duration: "4:03", url: "/audio/sixteen_tons/waltz_for_debbie_teafortwo.mp3" },
        { id: 13, title: "The More I See Of You - Garry Croony & Dr.V.Aks Band", duration: "2:47", url: "/audio/sixteen_tons/the_more_i_see_of_you_garry_croonydrvaks_band.mp3" },
        { id: 14, title: "On The Street Where You Live - Garry Croony & Dr.V.Aks Band", duration: "2:49", url: "/audio/sixteen_tons/on_the_street_where_you_live_garry_croonydr_v_aks_band.mp3" },
        { id: 15, title: "How Little We Know - Garry Croony & Dr.V.Aks-Band", duration: "2:43", url: "/audio/sixteen_tons/16. How Little We Know-Garry Croony&Dr.V.Aks-Band.mp3" },
        { id: 16, title: "I Love You Porgy - Dr.V.Aks-Band", duration: "6:27", url: "/audio/sixteen_tons/i_love_you_porgy_drvaks_band.mp3" }
      ]
    },
    {
      id: 2,
      title: "I'm Old Fashioned",
      image: "/images/albums/im-old-fashioned.jpg",
      tracks: [
        { id: 17, title: "A Fine Romance - Garry Croony", duration: "2:54", url: "/audio/i_m_old_fationed/A_fine_romance_garry_croony.mp3" },
        { id: 18, title: "All The Things You - Garry Croony", duration: "2:55", url: "/audio/i_m_old_fationed/All_the_things_you_garry_croony.mp3" },
        { id: 19, title: "I'm Old Fashioned - Tommy Flanagan & Garry Croony", duration: "3:13", url: "/audio/i_m_old_fationed/Im_old_fashioned_tommy_flanagangarry_croony.mp3" },
        { id: 20, title: "Long Ago And Far Away - Garry Croony", duration: "2:21", url: "/audio/i_m_old_fationed/Long_ago_and_far_away_garry_croony.mp3" },
        { id: 21, title: "Smoke Gets In Your Eyes - Garry Croony", duration: "3:34", url: "/audio/i_m_old_fationed/Smoke_gets_in_your_eyes_garry_croony.mp3" },
        { id: 22, title: "The Folks Who Live On The Hill - Garry Croony", duration: "2:39", url: "/audio/i_m_old_fationed/The_folks_who_live_on_the_hill_garry_croony.mp3" },
        { id: 23, title: "The Last Time I Saw Paris - Garry Croony", duration: "3:31", url: "/audio/i_m_old_fationed/The_last_time_i_saw_paris_garry_croony.mp3" },
        { id: 24, title: "The Way You Look Tonight - Garry Croony", duration: "3:49", url: "/audio/i_m_old_fationed/The_way_you_look_tonight_garry_croony.mp3" },
        { id: 25, title: "They Didn't Believe Me - Garry Croony", duration: "1:37", url: "/audio/i_m_old_fationed/They_didnt_belive_me_garry_croony.mp3" },
        { id: 26, title: "Yesterdays - Garry Croony", duration: "2:35", url: "/audio/i_m_old_fationed/Yesterdays_garry_croony.mp3" },
        { id: 27, title: "Sure Tthing - Garry Croony", duration: "2:24", url: "/audio/i_m_old_fationed/Sure_thing_garry_croony.mp3" }
      ]
    },
    {
      id: 3,
      title: "Garry Croony & Orchestra",
      image: "/images/albums/garry_croony&orchestra.jpg",
      tracks: [
        { id: 28, title: "Come Rain Or Come Shine - Garry Croony", duration: "4:27", url: "/audio/garry_croony_orchestra/come_rain_or_come_shine_garry_croony.mp3" },
        { id: 29, title: "Glimmer Of Your Smile - Garry Croony Jeff Meegan David Tobin", duration: "3:55", url: "/audio/garry_croony_orchestra/glimmer_of_your_smile_garry_croony_jeff_meegan_david_tobin.mp3" },
        { id: 30, title: "It Must Be Love - Garry Croony", duration: "2:44", url: "/audio/garry_croony_orchestra/it_must_be_love_garry_croony.mp3" },
        { id: 31, title: "My Foolish Heart - Garry Croony", duration: "2:52", url: "/audio/garry_croony_orchestra/my_fulish_heart_garry_croony.mp3" },
        { id: 32, title: "So Much To Live For Today - Garry Croony Jeff Meegan David Tobin", duration: "3:05", url: "/audio/garry_croony_orchestra/so_much_to_live_for_today_garry_croony_jeff_meegan_david_tobin.mp3" },
        { id: 33, title: "Stardust - Garry Croony", duration: "2:32", url: "/audio/garry_croony_orchestra/stardust_garry_croony.mp3" },
        { id: 34, title: "Stars Fell On Alabama - Garry Croony", duration: "3:49", url: "/audio/garry_croony_orchestra/stars_fell_on_alabama_garry_croony.mp3" },
        { id: 35, title: "Sunshine Of Your Love - Garry Croony Jeff Meegan David Tobin", duration: "3:14", url: "/audio/garry_croony_orchestra/sunshine_of_your_love_garry_croony_jeff_meegan_david_tobin.mp3" },
        { id: 36, title: "The Very Thought Of You - Garry Croony", duration: "3:45", url: "/audio/garry_croony_orchestra/the_very_thought_of_you_garry_croonyy.mp3" },
        { id: 37, title: "Whatever Lola Wants - Garry Croony", duration: "4:06", url: "/audio/garry_croony_orchestra/whatever_lola_wants_garry_croony.mp3" },
        { id: 38, title: "Where Or When - Garry Croony", duration: "2:16", url: "/audio/garry_croony_orchestra/where_or_when_garry_croony.mp3" },
        { id: 39, title: "You Me And Mistletoe - Garry Croony Tim Garland", duration: "2:15", url: "/audio/garry_croony_orchestra/you_me_and_mistletoe_garry_croony_tim_garland.mp3" }
      ]
    },
    {
      id: 4,
      title: "Black & White",
      image: "/images/albums/croony&shteinman.jpg",
      tracks: [
        { id: 40, title: "You Do Something To Me - Garry Croony & Vladimir Shteinman", duration: "2:10", url: "/audio/black_white/you_do_something_to_me_garry_croonyvladimir_shteinman.mp3" },
        { id: 41, title: "How Deep Is The Ocean - Garry Croony & Vladimir Shteinman", duration: "2:28", url: "/audio/black_white/how_deep_is_the_ocean_garry_croonyvladimir_shteinman.mp3" },
        { id: 42, title: "You And The Night And The Music", duration: "4:01", url: "/audio/black_white/you_and_the_night_and_the_music.mp3" },
        { id: 43, title: "Que Reste T Il De Nos Amours - Garry Croony & Vladimir Shteinman", duration: "4:04", url: "/audio/black_white/que_reste_t_il_de_nos_amours_garry_croonyvladimir_shteinman.mp3" },
        { id: 44, title: "You'd Be So Nice To Come Home To - Garry Croony & Vladimir Shteinman", duration: "2:13", url: "/audio/black_white/youd_be_so_nice_to_come_home_to_garry_croonyvladimir_shteinman.mp3" },
        { id: 45, title: "Mona Lisa - Garry Croony & Vladimir Shteinman", duration: "3:59", url: "/audio/black_white/mona_lisa_garry_croonyvladimir_shteinman.mp3" },
        { id: 46, title: "Nocturne Eb Shopin", duration: "4:01", url: "/audio/black_white/nocturne_eb_shopin.mp3" },
        { id: 47, title: "Just In Time - Garry Croony & Vladimir Shteinman", duration: "2:19", url: "/audio/black_white/just_in_time_garry_croonyvladimir_shteinman.mp3" },
        { id: 48, title: "Lush Life - Garry Croony", duration: "5:05", url: "/audio/black_white/lush_life_garry_croony.mp3" },
        { id: 49, title: "Angel Eyes - Garry Croony & Vladimir Shteinman", duration: "4:32", url: "/audio/black_white/angel_eyes_garry_croonyvladimir_shteinman.mp3" },
        { id: 50, title: "And I Love Her", duration: "3:34", url: "/audio/black_white/and_i_love_her.mp3" },
        { id: 51, title: "All The Things You Are - Garry Croony & Vladimir Shteinman", duration: "3:52", url: "/audio/black_white/all_the_things_you_are_garry_croonyvladimir_shteinman.mp3" },
        { id: 52, title: "I'm Old Fashioned - Garry Croony & Vladimir Shteinman", duration: "3:19", url: "/audio/black_white/im_old_fashioned_garry_croonyvladimir_shteinman.mp3" }
      ]
    },
    {
      id: 5,
      title: "Размышления в сумерках",
      image: "/images/albums/reflections_at_dusk.jpg",
      tracks: [
        { id: 53, title: "Будем знакомы", duration: "0:52", url: "/audio/reflections_at_dusk/budem_znakomy.mp3" },
        { id: 54, title: "Приди", duration: "1:49", url: "/audio/reflections_at_dusk/pridi.mp3" },
        { id: 55, title: "Где же тот сахар", duration: "2:17", url: "/audio/reflections_at_dusk/gde_zhe_tot_sakhar.mp3" },
        { id: 56, title: "Два раза была я замужем", duration: "2:00", url: "/audio/reflections_at_dusk/dva_raza_byla_ia_zamuzhem.mp3" },
        { id: 57, title: "Разговор с переписчиком", duration: "0:52", url: "/audio/reflections_at_dusk/razgovor_s_perepischikom.mp3" },
        { id: 58, title: "Возьму гарлемскую ночь", duration: "0:59", url: "/audio/reflections_at_dusk/vozmu_garlemskuiu_noch.mp3" },
        { id: 59, title: "Пришла я к милому", duration: "1:57", url: "/audio/reflections_at_dusk/prishla_ia_k_milomu.mp33" },
        { id: 60, title: "Я крикну эй", duration: "2:25", url: "/audio/reflections_at_dusk/ia_kriknu_ei.mp3" },
        { id: 61, title: "Вечером выпил крепко", duration: "2:04", url: "/audio/reflections_at_dusk/vecherom_vypil_krepko.mp3" },
        { id: 62, title: "Белые клавиши, чёрные руки", duration: "2:16", url: "/audio/reflections_at_dusk/belye_klavishi_chiornye_ruki.mp3" },
        { id: 63, title: "Размышления в сумерках", duration: "1:36", url: "/audio/reflections_at_dusk/razmyshleniia_v_sumerkakh.mp3" },
        { id: 64, title: "Стукнет мне", duration: "3:13", url: "/audio/reflections_at_dusk/stuknet_mne.mp3" }
      ]
    },
    {
      id: 6,
      title: "Joe Williams by TeaForTwo",
      image: "/images/albums/joe&williams.jpg",
      tracks: [
        { id: 65, title: "Don't Worry About Me - TeaForTwo", duration: "3:01", url: "/audio/joe_williams/dont_wary_about_me_teafortwo.mp3" },
        { id: 66, title: "You're Cheating Yourself - TeaForTwo", duration: "2:26", url: "/audio/joe_williams/youre_cheating_yourself_teafortwo.mp3" },
        { id: 67, title: "Autumn In New York - TeaForTwo", duration: "3:37", url: "/audio/joe_williams/autumn_in_new_york_teafortwo.mp3" },
        { id: 68, title: "A Child Is Born - TeaForTwo", duration: "3:58", url: "/audio/joe_williams/a_child_is_born_teafortwo.mp3" },
        { id: 69, title: "You Go To My Head - TeaForTwo", duration: "2:42", url: "/audio/joe_williams/you_go_to_my_head_teafortwo.mp3" },
        { id: 70, title: "Home - TeaForTwo", duration: "2:54", url: "/audio/joe_williams/home_teafortwo.mp3" },
        { id: 71, title: "Nobody's Heart - TeaForTwo", duration: "2:12", url: "/audio/joe_williams/nobodys_heart_teafortwo.mp3" },
        { id: 72, title: "Never Let Me Go - TeaForTwo", duration: "2:05", url: "/audio/joe_williams/never_let_me_go_teafortwo.mp3" },
        { id: 73, title: "Imagination - TeaForTwo", duration: "3:32", url: "/audio/joe_williams/imagination_teafortwo.mp3" },
        { id: 74, title: "Dat Dere - TeaForTwo", duration: "2:45", url: "/audio/joe_williams/dat_dere_teafortwo.mp3" },
        { id: 75, title: "Tenderly - TeaForTwo", duration: "3:20", url: "/audio/joe_williams/tenderly_teafortwo.mp3" },
        { id: 76, title: "Love Without Money - TeaForTwo", duration: "2:20", url: "/audio/joe_williams/love_without_money_teafortwo.mp3" },
        { id: 77, title: "Round Midnight - TeaForTwo", duration: "3:02", url: "/audio/joe_williams/round_midnight_teafortwo.mp3" },
        { id: 78, title: "Young At Heart - TeaForTwo", duration: "2:45", url: "/audio/joe_williams/young_at_heart_teafortwo.mp3" }
      ]
    },
    {
      id: 7,
      title: "Время, не нервируй меня...",
      image: "/images/albums/time.jpg",
      tracks: [
        { id: 79, title: "Моя любимая", duration: "2:16", url: "/audio/time/my_beloved.mp3" },
        { id: 80, title: "Журавли", duration: "3:57", url: "/audio/time/cranes.mp3" },
        { id: 81, title: "В землянке", duration: "2:18", url: "/audio/time/in_dugout.mp3" },
        { id: 82, title: "Потому что мы пилоты", duration: "2:43", url: "/audio/time/because_we_are_pilots.mp3" },
        { id: 83, title: "Прощайте, скалистые горы", duration: "3:09", url: "/audio/time/farewell_rocky_mountains.mp3" },
        { id: 84, title: "Песенка фронтового шофёра", duration: "2:55", url: "/audio/time/frontline_driver_song.mp3" },
        { id: 85, title: "Ты одессит, Мишка", duration: "4:17", url: "/audio/time/odessa_misha.mp3" },
        { id: 86, title: "Тёмная ночь", duration: "4:20", url: "/audio/time/dark_night.mp3" },
        { id: 87, title: "Любимый город", duration: "2:55", url: "/audio/time/beloved_city.mp3" },
        { id: 88, title: "Берёзовый сок", duration: "3:22", url: "/audio/time/birch_sap.mp3" },
        { id: 89, title: "Время, не нервируй мея...", duration: "3:10", url: "/audio/time/time_dont_make_me_nervous.mp3" }
      ]
    },
    {
      id: 8,
      title: "Дуэт 'Tea For Two' - Татьяна Красулина и Garry Croony",
      image: "/images/albums/krasulina&croony.jpg", 
      tracks: [
        { id: 90, title: "At Christmas Time TeaForTwo", duration: "3:06", url: "/audio/duet_tea_for_two/At_Christmas_Time_TeaForTwo.mp3" },
        { id: 91, title: "Down With Love TeaForTwo", duration: "2:42", url: "/audio/duet_tea_for_two/Down_With_Love_TeaForTwo.mp3" },
        { id: 92, title: "Great Big Smile TeaForTwo (David Tobin Jeff Meegan)", duration: "3:03", url: "/audio/duet_tea_for_two/Great_Big_Smile_TeaForTwo__David_Tobin_Jeff_Meegan_.mp3" },
        { id: 93, title: "Guess Who's in Town TeaForTwo", duration: "2:49", url: "/audio/duet_tea_for_two/Guess_Who_s_in_Town_TeaForTwo.mp3" },
        { id: 94, title: "It Don't Mean A Thing TeaForTwo", duration: "3:05", url: "/audio/duet_tea_for_two/It_Don_t_Mean_A_Thing_TeaForTwo.mp3" },
        { id: 95, title: "It Must Be Christmas Time TeaForTwo (David Tobin)", duration: "2:30", url: "/audio/duet_tea_for_two/It_Must_Be_Christmas_Time_TeaForTwo__David_Tobin_.mp3" },
        { id: 96, title: "Mele Kalikimaka TeaForTwo", duration: "2:56", url: "/audio/duet_tea_for_two/Mele_Kalikimaka_TeaForTwo.mp3" },
        { id: 97, title: "Samberiffic TeaForTwo", duration: "2:28", url: "/audio/duet_tea_for_two/Samberiffic_TeaForTwo.mp3" },
        { id: 98, title: "Shoop Da Boogie TeaForTwo (David Tobin Jeff Meegan)", duration: "2:34", url: "/audio/duet_tea_for_two/Shoop_Da_Boogie_TeaForTwo__David_Tobin_Jeff_Meegan_.mp3" },
        { id: 99, title: "Sweet And Low Down TeaForTwo", duration: "3:26", url: "/audio/duet_tea_for_two/Sweet_And_Low_Down_TeaForTwo.mp3" }
      ]
    },
    {
      id: 9,
      title: "Jazz in Blue Tones",
      image: "/images/albums/jaz_in__a_blue_tones.jpg?v=" + Date.now(),
      tracks: [
        { id: 100, title: "Don't Go To Strangers", duration: "4:58", url: "/audio/jazz_in_a_blue_tones/Don_t_Go_To_Strangers_Garry_Croony.mp3" },
        { id: 101, title: "It's Your Turn", duration: "2:50", url: "/audio/jazz_in_a_blue_tones/It_s_Your_Turn_Garry_Croony.mp3" },
        { id: 102, title: "Leaving So Soon", duration: "3:19", url: "/audio/jazz_in_a_blue_tones/Leaving_So_Soon_Garry_Croony_John_Dankworth.mp3" },
        { id: 103, title: "Save A Little Part", duration: "3:24", url: "/audio/jazz_in_a_blue_tones/Save_A_Little_Part_Garry_Croony_Tim_Garland_David_Tobin.mp3" },
        { id: 104, title: "Under The Moon", duration: "3:55", url: "/audio/jazz_in_a_blue_tones/Under_The_Moon_Garry_Croony__Jeff_Meegan__David_Tobin_.mp3" },
        { id: 105, title: "When Again", duration: "2:42", url: "/audio/jazz_in_a_blue_tones/When_Again_Garry_Croony.mp3" },
        { id: 106, title: "When I'm Next To You", duration: "2:35", url: "/audio/jazz_in_a_blue_tones/When_I_m_Next_To_You_Garry_Croony_Jeff_Meegan_David_Tobin.mp3" },
        { id: 107, title: "You'll Give Your Heart Away", duration: "3:48", url: "/audio/jazz_in_a_blue_tones/You_ll_Give_Your_Heart_Away_Garry_Croony.mp3" }
      ]
    },
    // {
    //   id: 10,
    //   title: "Джаз по Бокову",
    //   image: "/images/albums/dzhaz-po-bokovu.jpg",
    //   tracks: [
    //     { id: 108, title: "Don't Go To Strangers", duration: "4:58", url: "/audio/jazz_in_a_blue_tones/Don_t_Go_To_Strangers_Garry_Croony.mp3" },
    //     { id: 109, title: "It's Your Turn", duration: "2:50", url: "/audio/jazz_in_a_blue_tones/It_s_Your_Turn_Garry_Croony.mp3" },
    //     { id: 110, title: "You'll Give Your Heart Away", duration: "3:48", url: "/audio/jazz_in_a_blue_tones/You_ll_Give_Your_Heart_Away_Garry_Croony.mp3" }
    //   ]
    // },
    {
      "id": 11,
      "title": "Татьяна Красулина & Garry Croony (Tea For Two)",
      "image": "/images/albums/crasulina&croony.jpg",
      "tracks": [
        { "id": 111, "title": "Bewitched bothered and bewildered teafortwo", "duration": "3:42", "url": "/audio/tea_for_two/bewitched__bothered_and_bewildered_teafortwo.mp3" },
        { "id": 112, "title": "That sunday that summer teafortwo", "duration": "3:18", "url": "/audio/tea_for_two/that_sunday_that_summer_feafortwo.mp3" },
        { "id": 113, "title": "All that jazz teafortwo", "duration": "1:56", "url": "/audio/tea_for_two/all_that_jazz_teafortwo.mp3" },
        { "id": 114, "title": "Clouds teafortwo", "duration": "5:32", "url": "/audio/tea_for_two/clouds_teafortwo.mp3" },
        { "id": 115, "title": "Wisper not teafortwo", "duration": "3:15", "url": "/audio/tea_for_two/wisper_not_teafortwo.mp3" },
        { "id": 116, "title": "Someone to watch over me teafortwo", "duration": "4:28", "url": "/audio/tea_for_two/someone_to_watch_over_me_teafortwo.mp3" },
        { "id": 117, "title": "Too darn hot teafortwo", "duration": "2:54", "url": "/audio/tea_for_two/too_darn_hot_teafortwo.mp3" },
        { "id": 118, "title": "Willow weep for me teafortwo", "duration": "4:37", "url": "/audio/tea_for_two/willow_weep_for_me_teafortwo.mp3" },
        { "id": 119, "title": "Is you is or is you ain t teafortwo", "duration": "2:53", "url": "/audio/tea_for_two/is_you_is_or_is_you_ain_t_teafortwo.mp3" },
        { "id": 120, "title": "Then i ll be tired of you teafortwo", "duration": "3:21", "url": "/audio/tea_for_two/then_i_ll_be_tired_of_you_teafortwo.mp3" },
        { "id": 121, "title": "Cabare teafortwo", "duration": "3:40", "url": "/audio/tea_for_two/cabare_teafortwo.mp3" },
        { "id": 122, "title": "It don t mean a thing teafortwo", "duration": "2:15", "url": "/audio/tea_for_two/it_don_t_mean_a_thing_teafortwo.mp3" },
        { "id": 123, "title": "How do you keep the music playing teafortwo", "duration": "3:51", "url": "/audio/tea_for_two/how_do_you_keep_the_music_playing_teafortwo.mp3" },
        { "id": 124, "title": "Чай вдвоём", "duration": "3:24", "url": "/audio/tea_for_two/teafortwo_teafortwo.mp3" }
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Навигация */}
      <nav className="fixed w-full bg-black/90 backdrop-blur-sm z-50">
        <div className="flex justify-between items-center p-4">
          <a 
            href="#home"
            onClick={(e) => handleNavClick(e, 'home')}
            className="text-2xl font-bold hover:text-yellow-400 transition-colors duration-300"
          >
            Garry Croony
          </a>
          <button 
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden"
          >
            {isMenuOpen ? <X /> : <MenuIcon />}
          </button>
          <div className="hidden md:flex gap-6">
            {menuItems.map(item => (
              <a 
                key={item.id} 
                href={`#${item.id}`}
                className="hover:text-yellow-400 transition-colors duration-300"
                onClick={(e) => handleNavClick(e, item.id)}
              >
                {item.text}
              </a>
            ))}
          </div>
        </div>
      
        {/* Мобильное меню */}
        {isMenuOpen && (
          <div className="md:hidden absolute w-full bg-black/95 backdrop-blur-sm">
            {menuItems.map(item => (
              <a 
                key={item.id} 
                href={`#${item.id}`}
                className="block p-4 hover:bg-gray-800 transition-colors duration-300"
                onClick={(e) => handleNavClick(e, item.id)}
              >
                {item.text}
              </a>
            ))}
          </div>
        )}
      </nav>

      {/* Герой-секция */}
      <section id="home" className="min-h-screen flex items-center justify-center relative overflow-hidden">
        <Stars />
        <div 
          className="absolute inset-0 opacity-50"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/hero-bg.jpg)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            transform: 'scale(1.1)',
            transition: 'transform 0.3s ease-out'
          }}
        />
        <div className="relative z-10 text-center p-4" style={{ marginTop: '60vh' }}>
          <h1 className="text-6xl md:text-8xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-b from-yellow-300 via-yellow-500 to-yellow-300 fall-animation">
            Garry Croony
          </h1>
          <p className="text-3xl md:text-4xl mb-16 bg-clip-text text-transparent bg-gradient-to-b from-yellow-200 via-yellow-400 to-yellow-200 fall-animation-delay">
            Джазовый вокалист
          </p>
          <ChevronDown 
            className="animate-bounce mx-auto cursor-pointer" 
            style={{ opacity: 0, animation: 'fallIn 1.5s ease-out 1s forwards, bounce 1s infinite 2.5s' }}
            size={48} 
            onClick={() => scrollToSection('about')} 
          />
        </div>
      </section>

      {/* Секция О музыканте */}
      <section id="about" className="py-20 px-4 md:px-8 bg-gray-900">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-3xl font-bold mb-8">О музыканте</h2>
          <p className="text-lg leading-relaxed mb-4">
          <a href="https://web.beget.email/" target="_blank" rel="noopener noreferrer">Garry Croony</a> — талантливый джазовый вокалист, внесший вклад в развитие жанра. Его характерный тембр и индивидуальный стиль исполнения сделали его заметной фигурой на современной джазовой сцене.
          </p>
        </div>
      </section>

      {/* Секция Альбомы */}
      <section id="albums" className="py-20 px-4 md:px-8">
        <h2 className="text-3xl font-bold mb-8 text-center">Альбомы</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {albums.map(album => (
            <div 
              key={album.id} 
              className="bg-gray-900 rounded-lg overflow-hidden hover:transform hover:scale-105 transition-transform cursor-pointer" 
              onClick={() => setSelectedAlbum(album)}
            >
              <div
                className="w-full aspect-square bg-gray-700"
                style={{
                  backgroundImage: `url(${album.image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              ></div>
              <div className="p-4">
                <h3 className="text-xl font-bold mb-2">{album.title}</h3>
                <button
                  className="flex items-center gap-2 bg-yellow-400 text-black px-4 py-2 rounded-full hover:bg-yellow-300 transition-colors"
                >
                  <Play size={16} />
                  Слушать
                </button>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Галерея */}
      <section id="galery" className="py-20 px-4 md:px-8 bg-gray-900">
        <h2 className="text-3xl font-bold mb-8 text-center">Галерея</h2>
        <div className="columns-2 md:columns-3 lg:columns-4 gap-4 max-w-7xl mx-auto">
        {images.map((image, index) => (
            <GalleryImage key={index} src={image} index={index} />
          ))}
        </div>
      </section>

{/* Афиша */}
<section id="events" className="py-20 px-4 md:px-8">
        <h2 className="text-3xl font-bold mb-8 text-center">Выступления</h2>
        <div className="max-w-3xl mx-auto">
          {/* Блок для ближайших выступлений */}
          <div className="mb-12">
          <h3 className="text-2xl font-semibold mb-6">
            {new Date(new Date().setMonth(new Date().getMonth() + 1))
              .toLocaleString('ru-RU', { month: 'long', year: 'numeric' })
              .replace(/^./, str => str.toUpperCase())}
          </h3>    
            <div className="bg-gray-900 p-6 rounded-lg">
              <div className="flex flex-col md:flex-row gap-4 items-center">
                <div className="flex-grow">
                  <h4 className="text-xl font-bold mb-2">RockStarBar</h4>
                  <p className="text-gray-400">Казань, ул. Пушкина, 29А</p>
                </div>
                <button className="bg-yellow-400 text-black px-6 py-2 rounded-full hover:bg-yellow-300 transition-colors">
                  <a href="tel:884322609966">8 (8432) 260-99-66</a>
                </button>
              </div>
            </div>
            <div>&nbsp;</div>
            <div className="bg-gray-900 p-6 rounded-lg">
              <div className="flex flex-col md:flex-row gap-4 items-center">
                <div className="flex-grow">
                  <h4 className="text-xl font-bold mb-2">"Старый рояль"</h4>
                  <p className="text-gray-400">Казань, ул. Петербургская, 49</p>
                </div>
                <button className="bg-yellow-400 text-black px-6 py-2 rounded-full hover:bg-yellow-300 transition-colors">
                  <a href="tel:89872902501">8 (987) 290-25-01</a>
                </button>
              </div>
            </div>
          </div>
          <div className="mb-12">
          <h3 className="text-2xl font-semibold mb-6">
            {new Date().toLocaleString('ru-RU', { month: 'long', year: 'numeric' })
              .replace(/^./, str => str.toUpperCase())}
          </h3>          
          <div className="bg-gray-900 p-6 rounded-lg">
              <div className="flex flex-col md:flex-row gap-4 items-center">
                <div className="flex-grow">
                  <h4 className="text-xl font-bold mb-2">RockStarBar</h4>
                  <p className="text-gray-400">Казань, ул. Пушкина, 29А</p>
                </div>
                <button className="bg-yellow-400 text-black px-6 py-2 rounded-full hover:bg-yellow-300 transition-colors">
                  <a href="tel:884322609966">8 (8432) 260-99-66</a>
                </button>
              </div>
            </div>
            <div>&nbsp;</div>
            <div className="bg-gray-900 p-6 rounded-lg">
              <div className="flex flex-col md:flex-row gap-4 items-center">
                <div className="flex-grow">
                  <h4 className="text-xl font-bold mb-2">"Старый рояль"</h4>
                  <p className="text-gray-400">Казань, ул. Петербургская, 49</p>
                </div>
                <button className="bg-yellow-400 text-black px-6 py-2 rounded-full hover:bg-yellow-300 transition-colors">
                  <a href="tel:89872902501">8 (987) 290-25-01</a>
                </button>
              </div>
            </div>            
          </div>

          {/* Блок для приглашений */}
          <div className="bg-gray-900/50 backdrop-blur-sm p-8 rounded-lg text-center">
            <h3 className="text-2xl font-semibold mb-4">Частные выступления</h3>
            <p className="text-lg text-gray-300 mb-6">
              Доступен для частных мероприятий или совместных выступлений с другими музыкантами. Для уточнения деталей выступления свяжитесь по телефону:<br />
              <a href="tel:89063201415">8 (906) 320-14-15</a>
            </p>
            <button className="bg-yellow-400 text-black px-8 py-3 rounded-full hover:bg-yellow-300 transition-colors text-lg">
              Позвонить
            </button>
          </div>
        </div>
      </section>

      {/* Контакты */}
      <section id="contacts" className="py-20 px-4 md:px-8 bg-gray-900">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8">Контакты</h2>
          
          {/* Социальные сети */}
          <div className="flex justify-center gap-6 mb-8">
            <a 
              href="https://plvideo.ru/@garrycroony" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-white hover:text-yellow-400 transition-colors"
            >
              <Youtube size={32} />
            </a>
            <a 
              href="https://vk.com/garrycroony" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-white hover:text-yellow-400 transition-colors"
            >
              <svg width="32" height="32" viewBox="0 0 24 24" fill="currentColor">
                <path d="M15.07 2H8.93C3.33 2 2 3.33 2 8.93V15.07C2 20.67 3.33 22 8.93 22H15.07C20.67 22 22 20.67 22 15.07V8.93C22 3.33 20.67 2 15.07 2ZM18.15 16.27H16.69C16.14 16.27 15.97 15.82 14.86 14.72C13.86 13.77 13.49 13.67 13.27 13.67C12.95 13.67 12.87 13.76 12.87 14.18V15.77C12.87 16.08 12.75 16.27 11.82 16.27C10.42 16.27 8.85 15.35 7.71 13.69C6.09 11.38 5.57 9.58 5.57 9.23C5.57 9.05 5.65 8.89 5.97 8.89H7.43C7.76 8.89 7.91 9.03 8.06 9.42C8.82 11.41 10.06 13.16 10.57 13.16C10.78 13.16 10.86 13.07 10.86 12.59V10.5C10.81 9.44 10.33 9.37 10.33 9.06C10.33 8.94 10.43 8.89 10.58 8.89H12.84C13.12 8.89 13.22 9.03 13.22 9.4V12.17C13.22 12.46 13.35 12.55 13.44 12.55C13.65 12.55 13.82 12.46 14.23 12.06C15.23 11.01 15.96 9.45 15.96 9.45C16.06 9.24 16.24 9.05 16.62 9.05H18.08C18.45 9.05 18.54 9.24 18.45 9.54C18.24 10.37 16.76 12.41 16.76 12.41C16.58 12.69 16.53 12.78 16.76 13.09C16.93 13.31 17.45 13.77 17.79 14.16C18.42 14.88 18.91 15.5 19.03 15.87C19.17 16.27 18.93 16.27 18.15 16.27Z"/>
              </svg>
            </a>
            <a 
              href="https://wa.me/89063201415" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-white hover:text-yellow-400 transition-colors"
            >
              <MessageCircle size={32} />
            </a>
            <a 
              href="https://t.me/garrycroony_jazzvocal" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-white hover:text-yellow-400 transition-colors"
            >
              <svg width="32" height="32" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4.64 6.8c-.15 1.58-.8 5.42-1.13 7.19-.14.75-.42 1-.68 1.03-.58.05-1.02-.38-1.58-.75-.88-.58-1.38-.94-2.23-1.5-.99-.65-.35-1.01.22-1.59.15-.15 2.71-2.48 2.76-2.69a.2.2 0 00-.05-.18c-.06-.05-.14-.03-.21-.02-.09.02-1.49.95-4.22 2.79-.4.27-.76.41-1.08.4-.36-.01-1.04-.2-1.55-.37-.63-.2-1.12-.31-1.08-.66.02-.18.27-.36.74-.55 2.92-1.27 4.86-2.11 5.83-2.51 2.78-1.16 3.35-1.36 3.73-1.36.08 0 .27.02.39.12.1.08.13.19.14.27-.01.06-.01.13-.02.2z"/>
              </svg>
            </a>
          </div>
          
          <div className="space-y-4">
            <p className="text-xl">Email: info@garrycroony.ru</p>
            <p className="text-xl">Телефон: <a href="tel:89063201415" className="hover:text-yellow-400 transition-colors">+7 (906) 320-14-15</a></p>
          </div>
        </div>
      </section>

      {/* Модальное окно с плеером */}
      {selectedAlbum && (
        <EnhancedAudioPlayer
          album={selectedAlbum}
          albums={albums} // Передаем весь массив альбомов
          isOpen={true}
          onClose={() => setSelectedAlbum(null)}
          onAlbumChange={setSelectedAlbum} // Опционально: обновляем состояние текущего альбома
        />
      )}
    </div>
  );
};

export default LandingPage;